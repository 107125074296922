<div layout="column" layout-align="center center" flex>
	<section id="header-wrapper" flex>
		<div class="contents-block" layout="column" layout-align="center center">
			<h1>&lt; Sage Advice &gt;</h1>
			<h3>Because who doesn't like sage?</h3>
		</div>
	</section>
	<mat-card>
		<h3>Rules to code by.</h3>
		<div>
			Code so it's hard to break: make it easy to read and easy to test. Name your classes, methods, and variables clearly. 
			Each function should only do one thing.
		</div>
		<div>
			Think twice code once: don't just code for the sake of looking busy. Map a problem, think about what the inevitable 
			subsequent feature requests are going to be and prepare for those as well, then start coding knowing where 
			everything should go. 
		</div>
		<div>
			Don't code yourself into a corner. Scope three versions ahead and leave yourself open for easy wins in the future.
		</div>
		<div>
			Know when to re-invent the wheel and when to use a ready made component. There's hundreds of thousands of pre-built components and 
			libraries out there, use them where appropriate, but don't use junk just because it's already built.
		</div>
		<div>
			Code it ONCE! If you have the same code in multiple places you're setting yourself up for an unmanageable codebase and inevitable mistakes.
			Break it up into smaller reusable parts.
		</div>
		<div>
			When choosing libraries to use, ask three questions. Is it well documented? Is it likely to be updated regularly? 
			Does it solve the need without too much unnecessary bulk?
		</div>
	</mat-card>
</div>