<section id="intro-chunk" layout="column">
	<div class="contents-block" layout="column" layout-align="space-between center" flex>
		<h1>&lt; Web App Engineer &gt;</h1>
		<div class="intro-avatar"></div>
		<h2>Profesional Web Development Since 2005</h2>
	</div>
</section>
<section id="contact-me-wrapper" layout="column" layout-align="space-around center" flex>
	<h1>&lt; Contact Me &gt;</h1>
	<div layout="row" layout-align="center center">
		<mat-icon>contact_mail</mat-icon>
		<h3>Email me for more information.</h3>
	</div>
	<a href="mailto:cory@hau.me">
		<button mat-raised-button>Email Me!</button>
	</a>
</section>

<section id="footer-wrapper">
	<span>Copyright 2020 Cory Sougstad</span>
</section>