<div layout="column" layout-align="center center" flex>
	<section id="header-wrapper" flex>
		<div class="contents-block" layout="column" layout-align="center center">
			<h1>&lt; Job History &gt;</h1>
			<h3>More than ten years behind the keyboard daily</h3>
		</div>
	</section>
	<mat-card>
		<mat-card-header>
			<div mat-card-avatar class="insight-avatar"></div>
			<mat-card-title>Web Application Engineer - Insight Enterprises Inc.</mat-card-title>
			<mat-card-subtitle>October 2015 - Present (1 years 10 months) Tempe, AZ</mat-card-subtitle>
		</mat-card-header>
		<mat-card-content>
			<p>
				Full stack web, mobile app, and dev ops developer. Responsible for engineering an agile and scalable customer portal, 
				iOS & Android application, and API using Service Oriented Architecture. Work DevOps projects with network engineers 
				and other team members to automate and streamline internal processes and procedures. Implement unit tests across all 
				modules to prevent hard to catch mistakes. Expand local git repositories into a composable reusable library of 
				components. Collaborate with DBAs to generate reporting and analytics solutions for internal and customer facing 
				applications.
			</p>
		</mat-card-content>
		<mat-card-actions>
<!-- 			<button mat-button>Skills List</button> -->
		</mat-card-actions>
	</mat-card>
	
	<mat-card>
		<mat-card-header>
			<div mat-card-avatar class="responsivedata-avatar"></div>
			<mat-card-title>Manager of Information Technology - Responsive Data</mat-card-title>
			<mat-card-subtitle>March 2007 – October 2015 (8 years 8 months) Mesa, AZ</mat-card-subtitle>
		</mat-card-header>
		<mat-card-content>
			<p>
				I did so many things at Responsive Data having filled every job the IT department is responsible for at some point. 
				Starting as a Junior PHP Developer I was responsible for finding and crushing bugs and following project specifications 
				to build new features and functions. I aided the company in adopting version control (SVN), and helped create and manage 
				the repositories. When our systems administrator quit I took an evening class to grow my basic linux knowledge into a full 
				set of LAMP administration skills. When the IT Manager position opened I jumped at the opportunity and became responsible 
				for everything from creating project specifications, to employee reviews, to maintaining a vision and path for the growth 
				of our applications as well as the software and hardware we utilize. I’ve overseen the buyout and integration of numerous 
				competitors, hired and mentored new Jr developers into successful and effective employees, developed advanced server 
				configurations tuned to our company’s needs, and created and studied analytics to determine priorities and customer needs. 
				I really enjoyed the day to day problem solving and continuous adjustment of strategies based on the ever changing demands 
				of our clients, industries, and imaginations.
			</p>
		</mat-card-content>
		<mat-card-actions>
<!-- 			<button mat-button>Skills List</button> -->
		</mat-card-actions>
	</mat-card>
	
	<mat-card>
		<mat-card-header>
			<div mat-card-avatar class="edonor-avatar"></div>
			<mat-card-title>Support Developer - eDonor</mat-card-title>
			<mat-card-subtitle>December 2006 – February 2007 (3 months) Phoenix, AZ</mat-card-subtitle>
		</mat-card-header>
		<mat-card-content>
			<p>
				My support developer position at eDonor was a contract position where I was responsible for taking bug reports from the clients, 
				and then replicating and resolving the issue. The application I was supporting was built using primarily ColdFusion, and MSSQL 
				stored procedures. The code base was versioned by a CVS repository which is where I got my first version control experience.
			</p>
		</mat-card-content>
		<mat-card-actions>
<!-- 			<button mat-button>Skills List</button> -->
		</mat-card-actions>
	</mat-card>
	
	<mat-card>
		<mat-card-header>
			<div mat-card-avatar class="caringcomputerservices-avatar"></div>
			<mat-card-title>Software Developer - Caring Computer Services</mat-card-title>
			<mat-card-subtitle>July 2005 – December 2006 (1 year 6 months) Peoria, AZ</mat-card-subtitle>
		</mat-card-header>
		<mat-card-content>
			<p>
				I built web and compiled applications to help manage appointment booking, the listing and removing of thousands of ebay items, 
				and other day to day tasks. I also built an full custom e-commerce site for selling ink and toner cartridges. Aside from my 
				technical duties I was responsible for handling phone support and sales for projection screens and the printer ink.
			</p>
		</mat-card-content>
		<mat-card-actions>
<!-- 			<button mat-button>Skills List</button> -->
		</mat-card-actions>
	</mat-card>
</div>
