<div layout="column" layout-align="center center" flex>
	<section id="header-wrapper" flex>
		<div class="contents-block" layout="column" layout-align="center center">
			<h1>&lt; Skills &gt;</h1>
			<h3>Technology and concepts at my immediate disposal</h3>
		</div>
	</section>
	
	<mat-card *ngFor="let category of skillsList" flex>
		<mat-card-content>
			<h2>{{category.category}}</h2>
			<mat-list>
				<mat-list-item *ngFor="let skill of category.skills">
					{{skill.name}}
				</mat-list-item>
			</mat-list>
		</mat-card-content>
	</mat-card>


</div>