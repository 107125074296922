<div layout="column" layout-align="center center" flex>
	<section id="header-wrapper" flex>
		<div class="contents-block" layout="column" layout-align="center center">
			<h1>&lt; My Portfolio &gt;</h1>
			<h3>Projects I'm particularly proud of</h3>
		</div>
	</section>
	<section>
		<mat-card flex>
			<mat-card-header>
				<div mat-card-avatar class="rnocmobile-avatar"></div>
				<mat-card-title>Insight RNOC Mobile App</mat-card-title>
				<mat-card-subtitle>Customer Portal</mat-card-subtitle>
			</mat-card-header>
			<img mat-card-image src="assets/InsightRnocMobile.webp">
			<mat-card-content>
				<p>
					Insight Enterprises Inc’s first mobile application. Built cross platform (Android and iOS) using AngularJS, 
					and Cordova, the application leverages an internal REST API that I built using Zend Framework 2. The customer 
					facing mobile application gives a customer access to trouble ticket information, updates, and push 
					notifications; along with access to managed device information, projects, notices, and more.
				</p>
			</mat-card-content>
		</mat-card>
		
		<mat-card flex>
			<mat-card-header>
				<div mat-card-avatar class="ultimateprospector-avatar"></div>
				<mat-card-title>Ultimate Prospector Mobile App</mat-card-title>
				<mat-card-subtitle>CRM and Marketing</mat-card-subtitle>
			</mat-card-header>
			<img mat-card-image src="assets/UltimateProspectorMobile.webp">
			<mat-card-content>
				<p>
					Ultimate prospector is the mobile version of Responsive Data’s flagship CRM web application. Built with cordova, 
					HTML5, sass, and javascript, the ultimate prospector mirrors a lot of the features available in the web application 
					with the notable added benefit of being able to send text messages and receive real time notifications. Since the 
					business model requires a lot of rebranding and white labeling for clients the app was built in a way that it is 
					easy to theme, compile, and release to the app store, and helped manage releases of updates across the various 
					versions of the app on the markets.
				</p>
			</mat-card-content>
			<mat-card-actions>
				<a href="http://bit.ly/2rGaQl9" target="_blank"><button mat-button>View on Google Play</button></a>
			</mat-card-actions>
		</mat-card>
		<mat-card flex>
			<mat-card-header>
				<div mat-card-avatar class="realtimeleads-avatar"></div>
				<mat-card-title>Real Time Leads</mat-card-title>
				<mat-card-subtitle>eCommerce</mat-card-subtitle>
			</mat-card-header>
			<img mat-card-image src="assets/RealTimeLeads.webp">
			<mat-card-content>
				<p>
					RealTimeLeads.com is a ecommerce platform that Responsive Data uses to sell their lead packages to clients. The newest 
					version of the RTL platform allows sales staff to set up, theme, brand, launch, and manage new lead stores quickly and 
					easily. It also has built in customer service tools to allow for custom orders, and other admin needs without having to 
					open and use external intranet tools.
				</p>
			</mat-card-content>
			<mat-card-actions>
				<a href="http://RealTimeLeads.com" target="_blank"><button mat-button>Open Website</button></a>
			</mat-card-actions>
		</mat-card>
		
		<mat-card flex>
			<mat-card-header>
				<div mat-card-avatar class="ultimateprospector-avatar"></div>
				<mat-card-title>Ultimate Prospector</mat-card-title>
				<mat-card-subtitle>CRM and Marketing</mat-card-subtitle>
			</mat-card-header>
			<img mat-card-image src="assets/UltimateProspector1.webp">
			<mat-card-content>
				<p>
					Ultimate Prospector, Responsive Data’s flagship application, is a customer relationship manager. It helps people manage 
					everything from the generation of prospects, to working their sales process, and even marketing to them as a client or 
					customer and maintaining the that relationship. The tools offered range from email marketing, to customizable landing 
					pages, to time management and scheduling, tracking all actions back to the specific prospect, client, or customer for 
					future reference.
				</p>
			</mat-card-content>
			<mat-card-actions>
				<a href="http://UltimateProspector.com" target="_blank"><button mat-button>Open Website</button></a>
			</mat-card-actions>
		</mat-card>
		<mat-card flex>
			<mat-card-header>
				<div mat-card-avatar class="exclusiveclicks-avatar"></div>
				<mat-card-title>Exclusive Clicks</mat-card-title>
				<mat-card-subtitle>Affiliate Network Management</mat-card-subtitle>
			</mat-card-header>
			<img mat-card-image src="assets/ExclusiveClicks.webp">
			<mat-card-content>
				<p>
					Exclusive Clicks is an affiliate network management utility. The process starts with an affiliate applying to join the 
					network, then through a management utility the application is approved or denied. Once approved an affiliate can log in 
					and browse the campaigns they have available, choose banners, or other creatives, and run the ad on their website. Impressions, 
					clicks, and conversions are then tracked, and once an affiliate reaches their payout minimum, a management utility will 
					print out reports for payouts. Other management utilities allow for adding or editing campaigns and creatives, changing 
					commission rates, and targeting/blocking bad traffic sources.
				</p>
			</mat-card-content>
			<mat-card-actions>
				<a href="http://ExclusiveClicks.com" target="_blank"><button mat-button>Open Website</button></a>
			</mat-card-actions>
		</mat-card>
		
		<mat-card flex>
			<mat-card-header>
				<mat-icon mat-card-avatar>web</mat-icon>
				<mat-card-title>Your Website!</mat-card-title>
				<mat-card-subtitle>Beyond the edge of your imagination</mat-card-subtitle>
			</mat-card-header>
			<img mat-card-image src="assets/YourWebsite.webp">
			<mat-card-content>
				<p>
					Your project could be next! Looking for a new team member, or some quick contact work? Feel free to 
					<a href="mailto:cory@hau.me" target="_blank">contact me</a> and I'll let you know my availability. 
				</p>
			</mat-card-content>
		</mat-card>
	</section>
</div>