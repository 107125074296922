<div layout="column" layout-align="center center" flex>
	<section id="header-wrapper" flex>
		<div class="contents-block" layout="column" layout-align="center center">
			<h1>&lt; My Hobbies &gt;</h1>
			<h3>Create Recreate</h3>
		</div>
	</section>
	<div layout-gt-sm="row" layout-align="center start">
		<mat-card flex>
			<mat-card-header>
				<mat-card-title>Food!</mat-card-title>
			</mat-card-header>
			<img mat-card-image src="assets/HobbyBread.jpg">
			<mat-card-content>
				<p>
					I could call myself a foodie, but I think that gives the wrong idea; I love exploring, cooking, and eating all kinds of food!
					I appreciate everything from the high end gastroexperimental, to bar-s hotdogs. The bread loaf pictured is the result of
					of experimentation with propagating a sourdough starter from the wild bacteria and yeast naturally occurring in the air.
				</p>
			</mat-card-content>
		</mat-card>
		
		<mat-card flex>
			<mat-card-header>
				<mat-card-title>3d Printing and Design</mat-card-title>
			</mat-card-header>
			<img mat-card-image src="assets/Hobby3d.png">
			<mat-card-content>
				<p>
					I took board drafting and AutoCAD classes in high school and always wished I could take my designs from the virtual space into real world. 
					Christmas 2016 my dream became reality when I was gifted a 3d printer. I'll print hobby parts and models for myself and friends, design 
					random components, and have even created a child safety box for my electrical outlets. One day I'd like to design, print, and build a robot.
				</p>
			</mat-card-content>
		</mat-card>
	</div>
	<div layout-gt-sm="row" layout-align="center start">
		<mat-card flex>
			<mat-card-header>
				<mat-card-title>Hiking, Backpacking, and Camping</mat-card-title>
			</mat-card-header>
			<img mat-card-image src="assets/HobbyHiking.jpg">
			<mat-card-content>
				<p>
					I grew up in the Pacific Northwest and have always had a love for the outdoors. My dad taught me the local edible plant-life and I did a lot 
					of camping with him and the boy scouts. These days my family and I will take any opportunity to get into the mountains and enjoy the pines, 
					or take a stroll through the low desert when snow packs the rim roads.
				</p>
			</mat-card-content>
		</mat-card>
		
		<mat-card flex>
			<mat-card-header>
				<mat-card-title>Beer Brewing</mat-card-title>
			</mat-card-header>
			<img mat-card-image src="assets/HobbyBrewing.png">
			<mat-card-content>
				<p>
					I haven't been brewing as much since my son was born, but I really enjoy the process of taking grain, hops, and yeast, and turning them
					into cold frothy beer. It's a lot of fun experimenting with the different beer styles and customizing beers based on my own preferences.
				</p>
			</mat-card-content>
		</mat-card>
	</div>
</div>